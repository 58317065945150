/*
@font-face {
  font-family: SignOne;
  src: url('./fribourg-normal.ttf');
}
@font-face {
  font-family: HelloLove;
  src: url('./hello-love-regular.ttf');
}
@font-face {
  font-family: TheSalvador;
  src: url('./TheSalvador-Condensed.otf');
}
*/

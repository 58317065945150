/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-popover {
  --width: 320px;
}
.confirm-class .popover-content{
  width: 80%;
  left: 10%;
}

.xm-row-center{
  display: flex;
  justify-content: center;
}
.xm-row-left{
  display: flex;
  justify-content: left;
}
.xm-row-right{
  display: flex;
  justify-content: right;
}
.xm-row-space{
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(253, 255, 206, 0.27);
}



@font-face {
  font-family: SignOne;
  src: url('https://mitarbeiter.1apflegedienst.de/fonts/fribourg-normal.ttf');
}
@font-face {
  font-family: HelloLove;
  src: url('https://mitarbeiter.1apflegedienst.de/hello-love-regular.ttf');
}
@font-face {
  font-family: TheSalvador;
  src: url('https://mitarbeiter.1apflegedienst.de/fonts/TheSalvador-Condensed.otf');
}
